@import "../../assets/styles/scss/variables";

#page-header.calendar-header {
  width: 100%;
  margin-bottom: $gigantic;

  @media (max-width:576px) {
    margin-bottom: $huge;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: .5rem;

    @media (max-width:767px) {
      display: flex;
      flex-direction: column;
    }
  }

  .left {
    overflow: hidden;
  }

  .img-container {
    position: relative;
    padding-top: 100%;
    overflow: hidden;

    @media (max-width:991px) {
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .right {
    display: grid;
    grid-template-rows: 5fr 1fr;
    gap: .5rem;
    aspect-ratio: 1;

    @media (max-width:1199px) {
      display: flex;
      flex-direction: column;
      aspect-ratio: unset;

      .title {
        min-height: 20vh;
      }
    }

    @media (max-width:767px) {
      display: grid;
      grid-template-columns: 2fr 1fr;
    }

    @media (max-width:576px) {
      display: block;
      aspect-ratio: auto;

      .title {
        margin-bottom: .5rem;
      }
    }
  }

  .title, .dates, .schedule, .type {
    background-color: white;
  }

  .title {
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: .5rem;
  }

  .dates, .schedule, .type {
    padding: .8rem 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  ul.big {
    padding: 0;
    margin: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    align-items: stretch;
    gap: .5rem;

    @media (max-width:767px) {
      display: flex;
      flex-direction: column;

      li {
        height: 100%;
      }
    }

    @media (max-width:576px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      li.dates {
        grid-column: span 2;
      }
    }
  }
}