#socials-share {

    background-color: white;
    padding: 2rem 0 6rem;

    #post-share {
        font-weight: 100;
        margin-left: auto;
        max-width: 75%;

        p {
            font-weight: inherit;
        }

        a {
            color: $color-gray-darker;

            &:hover {
                color: $color-link-hover;
            }
        }

        @media (max-width:1199px) {
            max-width: 85%;
        }

        @media (max-width:991px) {
            max-width: 90%;
        }

        @media (max-width:767px) {
            max-width: 100%;
        }
    }

    .socials {
        padding: 1rem 0 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        gap: $large;

        i{
            color: $color-primary;
        }
        a {
            color: $color-primary;

            &:hover i{
                color: $color-gray !important;
            }
        }

        @media (max-width:767px) {
            gap: $medium;
        }
    }

    @media (max-width:576px) {
        background-color: transparent;
    }

}