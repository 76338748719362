@import "../../assets/styles/scss/variables";

#header {

  #logo {

    .logo-grid {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 0.5rem;
      .logo-part {
        overflow: hidden;
      }
      .logo-area-1, .logo-area-2{
        grid-column: span 2;
        grid-row: span 2;
      }
      .logo-area-3, .logo-area-4, .logo-area-5{
        grid-column: span 1;
        grid-row: span 1;
      }
      .logo-area-1, .logo-area-2, .logo-area-3, .logo-area-4, .logo-area-5{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        width: 100%;     
      }
      .no-mobile {
        width: 100%;
        transition-delay: 200ms;
        transition: width 0.2s cubic-bezier(0.075, 0.82, 0.165, 1), height 0.4s linear, margin 0.3s linear;
      }

      img {
        width: 100%;
        padding: 5px
      }

      .icon-bloc, svg {
        width: 100%;
        height: 100%;
      }
    }
    @media (max-width:767px) {

      a {
        height: 100%;
      }
    }
    
    &:hover {
      img {
        scale: 1;
      }
    }
  }

  &.sticky {

    #logo {
      .logo-grid {
        overflow: hidden;
        transition: display 0.5s ease-in-out;
        display: flex;
        row-gap: 0;
        column-gap: 0.5rem;
        .no-mobile {
          width: 0;
          margin-top: -200px;
          margin-left: -0.5rem;
          position: fixed;
        }
        .logo-part {
          aspect-ratio: 1;
          max-width: 4.5rem;
          max-height: 4.5rem;
        }
        .musee-jeu-logo {
          width: 4.5rem;
          height: 4.5rem;
        }
        img {
          padding: 0;
        }
      }
    }
    @media (max-width:1441px){
      #logo {
        .logo-grid{
          .musee-jeu-logo {
            width: 2.9rem;
            height: 2.9rem;
          }
          img, svg{
            width: 2.9rem;
            height: 2.9rem;
          }
        }
      }
    }
    @media (max-width:991px){
      #logo {
        .logo-grid{
          .musee-jeu-logo {
            width: 3.2rem;
            height: 3.2rem;
          }
          img, svg{
            width: 3.2rem;
            height: 3.2rem;
          }
        }
      }
    }
    @media (max-width:767px) {
      #logo {
        .logo-grid {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(1, 1fr);
          column-gap: 0.5rem;
        }
      }
    }
  }
  @media (max-width:767px) {

    #logo {
      .logo-grid {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(1, 1fr);
        column-gap: 0.5rem;
        .no-mobile {
          display: none;
        }
        .musee-jeu-logo {
          grid-column: span 1;
          width: 3.2rem;
          height: 3.2rem;
        }
        img {
          padding: 0;
        }
        img, svg{
          width: 3.2rem;
          height: 3.2rem;
        }
      }
    }
  }
}