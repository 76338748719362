@import "../../assets/styles/scss/variables";

.calendar-bloc {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0.5rem;

  .calendar-img {
    grid-column: span 4;
    border-radius: $rounded;
    overflow: hidden;
    width: 100%;
    padding-top: 100%;
    position: relative;

    a {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    img {
      border-radius: $rounded;
      height: 100%;
      max-width: 100%;
      width: 100%;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .calendar-title {
    grid-column: span 5;
    background-color: white;
    padding: 1.22rem 1.78rem;

    .small {
      color: $color-gray-darker;
      margin: 0;

      &.date {
        margin-top: $xsmall;
        margin-bottom: .5rem;
      }

      &.exceptions {
        padding: 0;
        margin: 0;
        list-style: none;
      }
    }
  }

  .calendar-info {
    grid-column: span 3;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    gap: 0.5rem;

    .calendar-type, .calendar-schedule, .calendar-link {
      display: flex;
      align-items: center;
      padding: 0 2.17rem;
      background-color: white;
    }

    .calendar-link {
      &:hover{
        box-shadow: $box-shadow;
    }
    }
  }

  @media (max-width: 991px){
    .calendar-img {
      grid-column: span 6;
    }
    .calendar-title {
      grid-column: span 6;
      padding: 2.17rem;
    }
    .calendar-info {
      grid-column: span 12;
      grid-template-columns: repeat(3, 1fr);    
      grid-template-rows: repeat(1,1fr);
      .calendar-type, .calendar-schedule, .calendar-date, .calendar-link {
        grid-column: span 1;
        gap: 0.5rem;
        padding: 2.17rem;
      }
    }
  }

  @media (max-width: 767px){
    .calendar-img {
      grid-column: span 12;
    }
    .calendar-title {
      grid-column: span 12;
      padding: 0.5rem 0.8rem;;
      min-height: 5.5rem;
    }
    .calendar-info {
      grid-template-columns: repeat(2, 1fr);
      .calendar-type, .calendar-schedule, .calendar-date, .calendar-link {
        grid-column: span 1;
        gap: 0.5rem;
        padding: 0.5rem 0.8rem;
      }

      .calendar-link {
        grid-column: span 2;
      }
    }
  }
}