.accordion {
  margin-bottom: .5rem;
  background-color: white;

  &:last-child {
    margin-bottom: 0;
  }

  .accordion-title {
    width: 100%;
    background-color: transparent;
    text-align: left;
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    svg {
      transform-origin: center center;
      transform: rotate(-180deg);
      transition: transform .3s linear;
      width: 1.6rem;
      min-width: 1.6rem;
      height: auto;
    }

    &.is-open {

      svg {
        transform: rotate(0);
      }
    }

    &:hover {

      svg {
        transform: rotate(-90deg);

        @media (max-width:767px) {
          transform: rotate(-180deg) !important;
        }
      }

      &.is-open {
        svg {
          transform: rotate(0);
        }
      }
    }
  }

  .accordion-content {
    display: none;
    margin-top: 1rem;
    padding: 1.5rem;
    padding-right: 8.5rem;
    padding-top: 0;
    font-weight: 100;

    @media (max-width:991px) {
      padding-right: 2rem;
    }

    a {
      color: $color-gray-darker;
      border-bottom: 2px solid $color-gray-darker;

      &:hover {
          color: $color-link-hover;
          border-color: $color-link-hover;
      }
    }
  }

  &.rates {

    .accordion-content {
      padding-right: 1.5rem;
    }
  }
}