@import "../../assets/styles/scss/variables";

#schedules {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: .6rem;
  background-color: $color-gray;
  padding: .6rem .8rem;

  .bullet-state {
    width: .6rem;
    height: .6rem;
    min-width: .6rem;
    min-height: .6rem;
    border-radius: 100%;
    background-color: $color-primary;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 48%;
      left: 48%;
      transform: translate(-50%, -50%);
      width: calc(100% + .3rem);
      height: calc(100% + .3rem);
      opacity: .3;
      border-radius: 100%;
      transform-origin: center;
      animation: blink .4s linear infinite alternate;
    }

    &.open {
      background-color: $color-green;

      &::before {
        background-color: $color-green;
      }
    }

    &.closed {
      background-color: $color-yellow;

      &::before {
        background-color: $color-yellow;
      }
    }

    &.fully-closed {
      background-color: $color-red;

      &::before {
        background-color: $color-red;
      }
    }
  }

  @keyframes blink {
    0% { transform: translate(-50%, -50%) scale(0) }
    60% , 100% { transform: translate(-50%, -50%)  scale(1)}
}
}