@import "../../assets/styles/scss/variables";

#page-header.standard-header {
  margin-bottom: $gigantic;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: .5rem;

  @media (max-width:991px) {
    display: flex;
    flex-direction: column;

    .right {
      aspect-ratio: inherit !important;
    }

  }

  @media (max-width:767px) {
    margin-bottom: $huge;
  }

  .img-container {
    position: relative;
    padding-top: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  h1 {
    max-width: 100%;
    margin-bottom: 0;
    background-color: white;
    padding: 1rem;
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: .5rem;
    aspect-ratio: 1;
  }

  .icon-bloc {
    display: inline-block;
    width: calc(100% / 6);
    height: auto;

    svg {
      width: 100%;
      height: auto;
    }
  }

  &.no-img {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: flex-start;

    .icon-bloc {
      width: auto;
      height: 7.2rem;
      max-height: 7.2rem;
      
      svg {
        width: auto;
        height: 100%;
      }

      @media (max-width:1199px) {
        height: 4.8rem;
        max-height: 4.8rem;
      }

      @media (max-width:767px) {
        height: 4rem;
        max-height: 4rem;
      }
    }
  }
}