//
// Font Faces Styles
// @help: url('../webfonts/xxx/')
// --------------------------------------------------

//Ease
@font-face {
  font-family: 'Ease Variable';
  src: url('../webfonts/ease/EaseStandard-Variable.woff2') format("woff2-variations"),
      url('../webfonts/ease/EaseStandard-Variable.woff') format("woff-variations");
  font-weight: 100 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ease';
  src: url('../webfonts/ease/EaseStandard-Bold.woff2') format('woff2'),
      url('../webfonts/ease/EaseStandard-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ease';
  src: url('../webfonts/ease/EaseStandard-Black.woff2') format('woff2'),
      url('../webfonts/ease/EaseStandard-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ease';
  src: url('../webfonts/ease/EaseStandard-Semibold.woff2') format('woff2'),
      url('../webfonts/ease/EaseStandard-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ease';
  src: url('../webfonts/ease/EaseStandard-Medium.woff2') format('woff2'),
      url('../webfonts/ease/EaseStandard-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ease';
  src: url('../webfonts/ease/EaseStandard-Regular.woff2') format('woff2'),
      url('../webfonts/ease/EaseStandard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


//Font Awesome
@font-face {
  font-family: "Font Awesome 6 Brands";
  src: url("../webfonts/fontawesome/fa-brands-400.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-brands-400.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Duotone";
  src: url("../webfonts/fontawesome/fa-duotone-900.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-duotone-900.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Light";
  src: url("../webfonts/fontawesome/fa-light-300.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-light-300.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Regular";
  src: url("../webfonts/fontawesome/fa-regular-400.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-regular-400.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Sharp";
  src: url("../webfonts/fontawesome/fa-sharp-900.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-sharp-900.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Solid";
  src: url("../webfonts/fontawesome/fa-solid-900.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-solid-900.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Thin";
  src: url("../webfonts/fontawesome/fa-thin-100.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-thin-100.ttf") format("truetype");
}