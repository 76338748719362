//
// Footer Styles
// --------------------------------------------------

#footer {
	font-size: 1rem;

	@media (max-width:1199px) {
    font-size: .8rem;
  }

  @media (max-width:767px) {
    font-size: $small;
  }

	#footer-top {
		background-color: white;
		padding: 3.5rem 0;

		@media (max-width:767px) {
			padding: 2rem 0;
		}

		a {
			&:hover {
				color: $color-link-hover !important;
			}
		}

		.container {
			display: flex;
			flex-direction: column;
			gap: .5rem;

			@media (max-width:1199px) {
				gap: 1rem;
			}
		}

		.top,
		.bottom {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			gap: 1rem;

			@media (max-width:1199px) {
				flex-direction: column;
				justify-content: flex-start;
			}
		}
		
		.bottom {
			align-items: flex-end;
			
			address {
				margin-top: 4.6rem;
			}
			
			a {
				color: $color-gray-darker;
			}
			
			@media (max-width:1199px) {
				align-items: flex-start;
			}
		}

		#schedules {
			margin-bottom: 1.5rem;
		}
		

		#footer-menu {
			font-size: $big;
			line-height: normal;

			ul {
				padding: 0;
				margin: 0;
				list-style: none;
				display: flex;
				flex-wrap: wrap;
				align-items: baseline;
				justify-content: flex-end;
				gap: 2rem;
			}

			@media (max-width:1199px) {
				font-size: $medium;
			}
		
			@media (max-width:767px) {
				font-size: $regular;
			}
		}

		.socials {
			padding: 0;
			margin: 0;
			list-style: none;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: flex-end;
			gap: $large;

			a {
				color: $color-text;

				&:hover {
					color: $color-text !important;
				}
			}

			@media (max-width:767px) {
				gap: $medium;
			}
		}

		@media (max-width:1199px) {
			#footer-menu ul,
			.socials {
				justify-content: flex-start;
			}
		}
		@media (max-width:767px) {
			#footer-menu ul {
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				gap: .5rem;
			}
		}
	}

	#footer-bottom {
		font-size: $xsmall;
		background-color: $color-gray;
		color: $color-text;
		padding: $xtrasmall 0;
		
		.container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: .5rem;
		}

		a {
			color: inherit;
		}

		@media (max-width:767px) {
			padding: 1.25rem;
			font-size: $xtrasmall;

			.container {
				flex-direction: column;
				gap: $xtrasmall;
			}
		}
	}


	.powered {
		position: relative;
		color: inherit;
		opacity: 1 !important;
		&:hover {
			color: inherit;
			text-decoration: none;
			#logo-alt-footer-trois {
				fill: #FFD94A;
				transform: scale(1.2);
			}
			#logo-alt-footer-deux {
				fill: #FFD94A;
				transform: scale(1.2);
			}
			#logo-alt-footer-un {
				fill: #FFD94A;
				transform: scale(1.2);
			}
			&:after {
				width: 100px;
			}
		}
		svg {
			overflow: visible;
			width: 50px;
			height: 24px;
			margin: 0 0 0 5px;
			position: relative;
			top: -2px;
			vertical-align: middle;
			width: 34px;
			#logo-alt-footer-trois {
				fill: transparent;
				transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
				transform: scale(1);
				transform-origin: center;
			}
			#logo-alt-footer-deux {
				fill: transparent;
				transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
				transform: scale(1);
				transform-origin: center;
			}
			#logo-alt-footer-un {
				fill: transparent;
				transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s;
				transform: scale(1);
				transform-origin: center;
			}
		}
		&:after {
			display: block;
			content: '';
			position: absolute;
			bottom: -2px;
			left: 83px;
			width: 0px;
			height: 2px;
			background-color: #FFD94A;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
		}
	}
}
