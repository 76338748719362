@import "../../assets/styles/scss/variables";

#page-header.activities-header {
  width: 100%;
  margin-bottom: $gigantic;

  @media (max-width:576px) {
    margin-bottom: $huge;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: .5rem;

    @media (max-width:1199px) {
      display: flex;
      flex-direction: column;

      .right {
        grid-template-rows: repeat(2, auto);
        gap: .5rem;
        aspect-ratio: auto;
      }
    }
  }

  .left {
    overflow: hidden;
  }

  .activities-image {
    margin: 0 !important;
  }

  .img-container {
    position: relative;
    padding-top: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .right {
    display: grid;
    grid-template-rows: repeat(6, 1fr);
    gap: .5rem;
    aspect-ratio: 1;

    @media (max-width:767px) {
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-template-rows: repeat(2, auto);
      aspect-ratio: auto;
    }

    @media (max-width:576px) {
      display: flex;
      flex-direction: column;
    }
  }

  .title, .dates, .duration, .age {
    background-color: white;
  }

  .title {
    grid-row: span 4;
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: .5rem;

    @media (max-width:1199px) {
      grid-row: span 1;
    }
  }

  .dates, .duration, .age, .booking-button {
    grid-row: span 1;
    padding: .8rem 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
  }

  @media (max-width:767px) {
    .booking-button{
      grid-column: span 2;
    }
  }


  ul.medium {
    padding: 0;
    margin: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    align-items: stretch;
    gap: .5rem;

    @media (max-width:767px) {
      display: flex;
      flex-direction: column;

      li {
        height: 100%;
      }
    }

    @media (max-width:576px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      li.dates {
        grid-column: span 2;
      }
    }
  }
}