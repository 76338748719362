@import "../../assets/styles/scss/variables";

.btn-file {
  width: 100%;
  padding: .6rem .8rem;
  background-color: $color-gray;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: .5rem;
  margin-bottom: .6rem;

  span {
    font-size: $xsmall;
    font-weight: normal;
    text-transform: uppercase;
    white-space: nowrap;
  }

  &:hover {
    background-color: $color-gray-darker;
  }
}