@import "../../assets/styles/scss/variables";

.gallery-bloc {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.6rem;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        border-radius: $rounded;
    }
    
    .gallery-item {
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      width: 0;
    }
    
    .gallery-item.show {
      opacity: 1;
      width: 100%;
    }
}
