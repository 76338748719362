// Base and helpers

html,
body {
  font-size: clamp(16px, 1.3vw, 25px);; //25px
  font-weight: 450;
  line-height: normal;
  background-color: $color-gray;
}

p, h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

.container {
  width: 80%;
  max-width: 1520px;
  margin: 0 auto;

  @media (max-width:1521px) {
    padding: 0 $small;
  }

  @media (max-width:576px) {
    width: 100%;
  }
}

.is-hidden,
.hidden {
  display: none !important;
}

.color-primary,
.color-black {
  color: $color-primary;
}

.hover-color-black {
  &:hover {
    color: $color-primary;
  }
}

.color-secondary {
  color: $color-secondary;
}

.color-blue {
  color: $color-blue;
}

.hover-color-blue {
  &:hover {
    color: $color-blue;
  }
}

.color-purple {
  color: $color-purple;
}

.hover-color-purple {
  &:hover {
    color: $color-purple;
  }
}

.color-red {
  color: $color-red;
}

.hover-color-red {
  &:hover {
    color: $color-red;
  }
}

.color-yellow {
  color: $color-yellow;
}

.hover-color-yellow {
  &:hover {
    color: $color-yellow;
  }
}

.color-green {
  color: $color-green;
}

.hover-color-green {
  &:hover {
    color: $color-green;
  }
}

.bg-primary {
  background-color: $color-primary;
}

.hover-bg-primary {
  &:hover{
    background-color: $color-primary !important;
    color: white !important;
  }
}

.hover-bg-primary.reverse-bg {
  background-color: $color-primary;
  color: white;
  &:hover{
    background-color: white !important;
    color: $color-primary !important;
  }
}

.bg-black {
  background-color: $color-primary;
}

.hover-bg-black {
  &:hover{
    background-color: $color-primary !important;
    color: white !important;
  }
}

.hover-bg-black.reverse-bg {
  background-color: $color-primary;
  color: white;
  &:hover{
    background-color: white !important;
    color: $color-primary !important;
  }
}

.bg-secondary {
  background-color: $color-secondary;
}

.hover-bg-secondary {
  &:hover{
    background-color: $color-secondary !important;
    color: white !important;
  }
}

.hover-bg-secondary.reverse-bg {
  background-color: $color-secondary;
  color: white;
  &:hover{
    background-color: white !important;
    color: $color-primary !important;
  }
}

.bg-blue {
  background-color: $color-blue;
}

.hover-bg-blue {
  &:hover{
    background-color: $color-blue !important;
    color: white !important;
  }
}

.hover-bg-blue.reverse-bg {
  background-color: $color-blue;
  color: white;
  &:hover{
    background-color: white !important;
    color: $color-primary !important;
  }
}

.bg-purple {
  background-color: $color-purple;
}

.hover-bg-purple {
  &:hover{
    background-color: $color-purple !important;
    color: white !important;
  }
}

.hover-bg-purple.reverse-bg {
  background-color: $color-purple;
  color: white;
  &:hover{
    background-color: white !important;
    color: $color-primary !important;
  }
}

.bg-red {
  background-color: $color-red;
}

.hover-bg-red {
  &:hover{
    background-color: $color-red !important;
    color: white !important;
  }
}

.hover-bg-red.reverse-bg {
  background-color: $color-red;
  color: white;
  &:hover{
    background-color: white !important;
    color: $color-primary !important;
  }
}

.bg-yellow {
  background-color: $color-yellow;
}

.hover-bg-yellow {
  &:hover{
    background-color: $color-yellow !important;
    color: white !important;
  }
}

.hover-bg-yellow.reverse-bg {
  background-color: $color-yellow;
  color: white;
  &:hover{
    background-color: white !important;
    color: $color-primary !important;
  }
}


.bg-green {
  background-color: $color-green;
}

.hover-bg-green {
  &:hover{
    background-color: $color-green !important;
    color: white !important;
  }
}

.hover-bg-green.reverse-bg {
  background-color: $color-green;
  color: white;
  &:hover{
    background-color: white !important;
    color: $color-primary !important;
  }
}

.hero-title {

  strong {
    display: block;
    color: $color-gray-light;
  }
}

.highlight {
  border-radius: $rounded;
  background-color: white;
  color: $color-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: 350;
  width: fit-content;

  &.w-icon {
    justify-content: flex-start;
    text-align: left;
    gap: 1.5rem;

    .icon {
      width: 1.5rem;
      height: auto;
    }
  }
}

.smaller-container {
  width: 100%;
  max-width: 66%;
  margin: 0 auto;

  @media (max-width:991px) {
    max-width: 83%;
  }

  @media (max-width:767px) {
    max-width: 100%;
  }
}

.medium-container {
  width: 100%;
  max-width: 83%;
  margin: 0 auto;

  @media (max-width:991px) {
    max-width: 90%;
  }

  @media (max-width:767px) {
    max-width: 100%;
  }
}

.xtrasmall {
  font-size: $xtrasmall;
  line-height: normal;

  @media (max-width:767px) {
    font-size: .8rem;
  }
}

.xsmall {
  font-size: $xsmall;
  line-height: normal;

  @media (max-width:767px) {
    font-size: $xtrasmall;
  }
}

.small {
  font-size: $small;
  line-height: normal;

  @media (max-width:767px) {
    font-size: $xsmall;
  }
}

p,
.regular {
  font-size: clamp($small, 1.7vw, $regular);
  line-height: 1.4em;
}

p {
  margin-bottom: .8rem;

  &:last-child {
    margin-bottom: 0;
  }

  strong {
    font-weight: 500;
  }
}

h6,
.medium {
  font-family: $font-title;
  font-size: clamp($small, 2vw, $medium);
  line-height: 100%;
  font-weight: 450;
}

h5,
.big {
  font-family: $font-title;
  font-size: clamp(22px, 3vw, $big);
  line-height: 100%;
  font-weight: 450;
}

h4,
.large {
  font-family: $font-title;
  font-size: clamp($big, 3.5vw, $large);
  line-height: 100%;
  font-weight: 450;
}

h3,
.massive {
  font-family: $font-title;
  font-size: clamp($large, 4vw, $massive);
  line-height: 100%;
  font-weight: 450;
}

h2,
.huge {
  font-family: $font-title;
  font-size: clamp($massive, 3.5vw, $huge);
  line-height: 100%;
  font-weight: 450;
}

h1,
.gigantic {
  font-family: $font-title;
  font-size: clamp($huge, 10vw, $gigantic);
  line-height: 100%;
  font-weight: 450;
}

#page-header .right h1,
#page-header .right .gigantic {
  font-size: clamp($huge, 5vw, 4.2rem);
}

.btn-primary {
  font-size: $regular;
  line-height: normal;
  font-weight: 450;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  color: white;
  background-color: $color-primary;
  border: none;
  border-radius: 4.8rem;
  padding: .5rem 1.2rem;
  transition: all .2s linear;

  &:hover {
    background-color: $color-primary-dark;
    color: white;
  }

  &.invert,
  &.reverse {
    color: $color-primary;
    border: 2px solid $color-primary;
    background-color: white;

    &:hover {
      background-color: $color-primary;
      color: white;
    }
  }
}

.btn-secondary {
  font-size: $regular;
  line-height: normal;
  font-weight: 450;
  color: $color-primary;
  width: fit-content;
  padding-bottom: .3rem;
  border-bottom: 1px solid $color-primary;
  transition: all .2s linear;

  &:hover {
    color: $color-primary-dark;
    border-color: $color-primary-dark;
  }
}

.rounded {
  border-radius: $rounded;

  @media (max-width:767px) {
    border-radius: $rounded-mobile;
  }
}

.btn-white {
  color: $color-primary;
  border-radius: 6.25rem;
  background-color: white;
  padding: 0.38rem;
  text-align: center;
  &:hover {
    background-color: $color-primary;
    color: white;
  }
}

svg.icon {
  &.black {

    path {
      fill: $color-black;
    }
  }
  &.purple {

    path {

      &.black {

    path {
      fill: $color-black;
    }
  }
      fill: $color-purple;
    }
  }

  &.red {

    path {
      fill: $color-red;
    }
  }

  &.yellow {

    path {
      fill: $color-yellow;
    }
  }

  &.green {

    path {
      fill: $color-green;
    }
  }

  &.blue {

    path {
      fill: $color-blue;
    }
  }
}

.btn-form {
  border-radius: 6.25rem;
  background-color: white;
  padding: 0.38rem;
  text-align: center;
}