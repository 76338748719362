//
// Template Styles
// --------------------------------------------------


#main {
    padding-top: 387px; // Header height
    z-index: 15;

    @media (max-width:1441px) {
        padding-top: 304px;
    }

    @media (max-width:767px) {
        padding-top: 100px;
    }
}

#page-header.standard-header {
    @media (max-width:767px) {
        margin-bottom: $huge !important;
    }

    .right {
        justify-content: flex-start !important;
    }
}

#news {
    padding: 5rem 0;

    .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: .5rem;

        @media (max-width:767px) {
            display: flex;
            flex-direction: column;
            gap: 2rem;
        }
    }
}

#post-content {
    background-color: white;
    padding: 3.5rem 0;

    @media (max-width:576px) {
        background-color: transparent;
        padding: 2rem 0;
    }

    .intro {
        margin-bottom: 4rem;
    }

    .descr {
        font-weight: 100;
        margin-left: auto;

        p {
            font-weight: inherit;
        }

        a:not(.btn-primary) {
            color: $color-gray-darker;
            border-bottom: 2px solid $color-gray-darker;

            &:hover {
                color: $color-link-hover;
                border-color: $color-link-hover;
            }
        }

        h2,
        h3,
        h4,
        h5,
        h6 {
            margin: .9rem 0;
        }
    }

    .intro,
    .descr {
        max-width: 75%;

        @media (max-width:1199px) {
            max-width: 85%;
        }

        @media (max-width:991px) {
            max-width: 90%;
        }

        @media (max-width:767px) {
            max-width: 100%;
        }
    }
}

.exhibitions-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: .5rem;

    @media (max-width:1199px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width:767px) {
        display: flex;
        flex-direction: column;
    }
}

#page-homepage {

    #exhibitions {
        margin: 8rem 0;


        h2 {
            margin-bottom: $large;
        }

        @media (max-width:576px) {
            margin: 5rem 0;
        }
    }

    #news {
        margin: 4rem 0;

        h2 {
            margin-bottom: $large;
        }

        @media (max-width:576px) {
            margin: 5rem 0;
        }
    }
}

#page-exhibitions {

    #currents {
        display: flex;
        flex-direction: column;
        gap: 8rem;
        margin-bottom: 10rem;

        @media (max-width:767px) {
            gap: 5rem;
            margin-bottom: 5rem;
        }
    }

    #oldExhibitions {
        margin-bottom: 8rem;

        h2 {
            margin-bottom: 1.6rem;
        }
    }
}

#page-blog {

    #news {
        margin-bottom: 6rem;

        .grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: .5rem;
            row-gap: 3.2rem;

            @media (max-width:767px) {
                display: flex;
                flex-direction: column;
                gap: 2rem;
            }
        }
    }
}

#single-news {

    #news {

        h3 {
            margin-bottom: 1.84rem;
        }

        .grid {
            display: flex;
            flex-direction: column;
            gap: 3rem;
        }
    }

    #post-content {
        margin: 6rem auto;
        margin-bottom: 0;
    }
}

#single-activity {

    #news {

        h3 {
            margin-bottom: 1.84rem;
        }
    }

    #post-content {
        margin: 6rem auto 0rem;
    }

    .cta-btn {
        background-color: white;
        padding: 2rem 0 6rem;

        .booking-button {
            width: 100%;
            margin-left: auto;
            max-width: 75%;

            &:hover {
                background-color: $color-gray !important;
            }

            @media (max-width:1199px) {
                max-width: 85%;
            }

            @media (max-width:991px) {
                max-width: 90%;
            }

            @media (max-width:767px) {
                max-width: 100%;
            }
        }
    }

    #news {
        margin: 6rem auto;

        .grid {
            display: flex;
            flex-direction: column;
            gap: 3rem;
        }
    }
}

#single-calendar {
    #post-content {
        margin: 6rem auto 0rem;
    }

    #news {
        margin: 6rem 0;

        h3 {
            margin-bottom: 1.84rem;
        }
    }
}

#page-activities {

    #activities {
        margin-bottom: 6rem;

        .grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: .5rem;
            row-gap: 3.2rem;

            @media (max-width:767px) {
                display: flex;
                flex-direction: column;
                gap: 2rem;
            }
        }
    }
}

#single-activities {

    #news {

        h3 {
            margin-bottom: 1.84rem;
        }
    }

    #news,
    #post-content {
        margin: 6rem auto;
    }
}

#single-exhibition {

    #accordions {
        padding: 6rem 0;

        @media (max-width:767px) {
            padding: 3rem 0;
        }
    }

    #partners {
        padding: 6rem 0;

        .partners-container {
            padding: 1.62rem 2.4rem;
            display: grid;
            background-color: white;
            border-radius: $rounded;
            gap: 4.3rem;
            grid-template-columns: repeat(1, 1fr);

            .partners {
                overflow: hidden;
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                gap: .5rem;

                .slider {
                    height: 3.2rem;

                    .slick-list {
                        height: 3.2rem;

                        .slick-track {
                            height: 3.2rem;

                            .partner-card {
                                width: auto;
                                height: 3.2rem;
                                margin: 0 3.6rem 0 0;

                                img {
                                    height: 3.2rem;
                                    width: auto;
                                    object-fit: cover;
                                    object-position: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#page-infos {

    #accordions {
        margin-bottom: 10rem;

        @media (max-width:767px) {
            margin-bottom: 5rem;
        }
    }
}

#rate {
    margin-bottom: 2.2rem;
    padding: 2.2rem;
    background-color: $color-gray;

    h3 {
        margin-bottom: 1rem;
    }

    .prices {
        padding: 0;
        margin: 0;
        list-style: none;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        row-gap: 0;

        @media (max-width:991px) {
            display: flex;
            flex-direction: column;
        }

        .price {
            border-top: 1px solid $color-gray-darker;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: .5rem;
            row-gap: .6rem;
            padding: 1rem 0;

            &:first-child,
            &:nth-child(2) {
                border-top: none;
            }

            .left,
            .special.xtrasmall:not(.amount) {
                width: 75%;
            }

            .amount,
            .amount.special {
                white-space: nowrap;
                text-align: right;
                font-weight: 450;
                width: calc(25% - .5rem);
            }

            .special {
                color: $color-gray-darker;
            }
        }
    }
}

#page-museum,
#page-foundation,
#page-association {
    .page-section {
        padding: 0 0 5.69rem;
    }
}

#page-store {
    .gallery-section {
        padding: 6rem 0;

        @media (max-width:767px) {
            padding: 3rem 0;
        }
    }

    .store-accordion {
        padding: 0 0 6rem 0;

        @media (max-width:767px) {
            padding: 3rem 0;
        }
    }
}

#page-museum {
    .member-accordion {
        padding: 6rem 0;

        @media (max-width:767px) {
            padding: 3rem 0;
        }
    }
}

#page-rooms {
    .member-section {
        padding: 6rem 0;

        @media (max-width:767px) {
            padding: 3rem 0;
        }
    }
}

#page-contact {
    #post-content {
        background-color: $color-gray;
    }

    .contact-content {
        display: grid;
        grid-template-columns: repeat(12, 1fr);

        .contact-info {
            grid-column: span 5;
            gap: 0.5rem;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: flex-start;
            justify-content: space-between;

            h2 {
                padding: 1.03rem 1.17rem;
                background-color: white;
                height: fit-content;
                width: 100%;
            }

            .btn-form {
                text-align: left;
                background-color: white;
                padding: 1.03rem 1.17rem;
                width: calc(50% - 0.3rem);
                height: fit-content;
                border-radius: $rounded;

                &:hover {
                    box-shadow: $box-shadow;
                }

                @media (max-width: 767px) {
                    border-radius: $rounded-mobile;
                }
            }

            .btn-street {
                background-color: white;
                padding: 1.03rem 1.17rem;
                width: calc(80% - 0.3rem);
                height: auto;

                &:hover {
                    box-shadow: $box-shadow;
                }
            }

            .icon-bloc {
                width: calc(20% - 0.3rem);
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: white;
            }
        }

        .contact-form {
            grid-column: none;
            grid-column-start: 7;

            .gform_heading {
                display: none;
            }

            .button {
                padding: 1.36rem 1.6rem;
                border-radius: $rounded;
                width: 100%;
                text-align: left;

                &:hover {
                    box-shadow: $box-shadow;
                }

                @media (max-width: 767px) {
                    border-radius: $rounded-mobile;
                }
            }
        }
    }

    @media (max-width:1480px) {
        .contact-content {
            .contact-info {

                .btn-white,
                .btn-form {
                    width: 100%;
                }
            }
        }
    }

    @media (max-width:1199px) {
        .contact-content {
            .contact-info {
                .btn-street {
                    width: 100%;
                }

                .icon-bloc {
                    width: 3.3rem;
                    order: 5;
                    max-height: 3.3rem;
                    display: block;
                    background-color: transparent;

                    svg {
                        height: 100%;
                        width: auto;
                    }
                }
            }
        }
    }

    @media (max-width:640px) {
        .contact-content {
            grid-template-columns: repeat(12, 1fr);
            row-gap: 2rem;

            .contact-info {
                grid-column: span 12;

                h2 {
                    width: 100%;
                    padding: 0.8rem 1rem;
                }

                .btn-white {
                    padding: 0.8rem 1rem;
                    text-align: left;
                }

                .btn-street {
                    padding: 0.8rem 1rem;
                    text-align: left;
                }

                .icon-bloc {
                    aspect-ratio: 1;

                    svg {
                        aspect-ratio: 1;
                        height: auto;
                        width: 100%;
                    }
                }
            }

            .contact-form {
                grid-column: span 12;
            }
        }
    }
}

#page-not-found {
    margin: 3rem auto 6rem;

    .error-actions {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 0.5rem;

        .error-title {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 0.5rem;

            .four {
                background-color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 10rem;
            }
        }

        .error-content {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 0.5rem;
        }

        .error-button {
            padding: 1rem 1.6rem;
            justify-content: flex-start;
            width: 100%;
            height: 100%;
            align-items: center;
            display: flex;
        }
    }

    @media (max-width: 1520px) {
        .error-actions {
            .error-title {
                .four {
                    font-size: 7rem;
                }
            }
        }
    }

    @media (max-width: 1199px) {
        .error-actions {
            .error-title {
                .four {
                    font-size: 5rem;
                }
            }
        }
    }

    @media (max-width: 440px) {
        .error-actions {
            .error-title {
                .four {
                    font-size: 3rem;
                }
            }
        }
    }
}

#page-calendar {

    #exhibitions {
        margin: 8rem 0 0;

        h2 {
            margin-bottom: $large;
        }

        @media (max-width:576px) {
            margin: 5rem 0;
        }
    }

    #calendar {
        margin: 8rem 0;

        #empty-msg {
            display: none;
        }

        .months-nav {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
            align-items: center;
            margin-bottom: $large;

            .prev-month,
            .next-month {
                padding: .5rem;

                &:hover {
                    font-weight: 400;
                    color: $color-primary;

                    i {
                        color: inherit;
                        font-weight: inherit;
                    }
                }
            }

            .months {
                padding: 0;
                margin: 0;
                list-style: none;

                .month {
                    display: none;
                    visibility: hidden;

                    &.is-active {
                        display: initial;
                        visibility: visible;
                    }
                }
            }
        }

        .grid {
            display: grid;
            grid-template-columns: 1;
            gap: 3rem;

            .calendar-bloc {
                display: none;
                visibility: hidden;

                &.is-active {
                    display: grid;
                    visibility: visible;
                }
            }
        }

        @media (max-width:576px) {
            margin: 5rem 0;
        }
    }
}