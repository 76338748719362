@import "../../assets/styles/scss/variables";

.icon-bloc {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $rounded;

  @media (max-width:767px) {
    border-radius: $rounded-mobile;
  }

  svg {
    width: 100%;
    height: auto;

    rect.bg {
      fill: white !important;
    }
    &.blue {

      path, rect, circle {
        fill: $color-blue;
      }
    }

    &.purple {

      path, rect, circle {
        fill: $color-purple;
      }
    }

    &.red {

      path, rect, circle {
        fill: $color-red;
      }
    }

    &.yellow {

      path, rect, circle {
        fill: $color-yellow;
      }
    }

    &.green {

      path, rect, circle {
        fill: $color-green;
      }
    }

    &.black {

      path, rect, circle {
        fill: $color-primary;
      }
    }
  }
}