@import "../../assets/styles/scss/variables";

.rooms-bloc {
    padding: 3rem 0;

    &:last-child {
        margin-bottom: 5rem;
    }

    &:nth-child(even) {

        .room-bloc {

            .room-slider {
                order: 2;
            }
        }
    }
}

.room-bloc {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5rem;

    .slick-dotted.slick-slider {
        margin-bottom: 0 !important;
    }

    .room-slider {
        display: grid;
        grid-column: span 1;

        .slick-slider {
            border-radius: $rounded;
            display: grid;

            @media (max-width: 767px){
                border-radius: $rounded-mobile;
            }

            .slick-list {
                border-radius: $rounded;
                height: 100%;
                width: 100% !important;
                
                @media (max-width: 767px){
                    border-radius: $rounded-mobile;
                }

            }
            .slick-track{
                height: 100%;
            }
            img {
                height: 100%;
                aspect-ratio: 1;
                object-fit: cover;
                object-position: center center;
                border-radius: $rounded;
                
                @media (max-width: 767px){
                    border-radius: $rounded-mobile;
                }
                
            }
            .slick-dots {
                width: 100%;
                right: 0;
                li {
                    button {
                        background-color: white !important;
                    }
                }
            }
        }
    }

    .room-infos {
        grid-column: span 1;
        display: grid;
        grid-gap: 0.5rem;
        grid-template-columns: repeat(6, 1fr);
        flex-direction: column;

        .room-bloc-title {
            grid-column: span 6;
            padding: 1.6rem 1.2rem;
            width: 100%;
            background-color: white;
        }

        .room-capacity, .room-rate {
            grid-column: span 3;
            display: flex;
            padding: 1.6rem 1.2rem;
            flex-direction: column;
            background-color: white;
            
            h5{
                margin-bottom: 1.2rem;
            }

            ul {
                margin: 0;
                padding: 0;

                li {
                    padding: 0.5rem 0;
                    list-style-type: none;
                    border-bottom: solid 1px #E5E5E5;
                }

                li:last-child {
                    border-bottom: 0;
                }
            }
        }

        .icon-bloc{
            grid-column: span 1;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            aspect-ratio: 1;
            svg{
                height: 100%;
                width: 100%;
            }
        }

        .room-booking {
            grid-column: span 5;
            .booking-button{
                padding:0 0 0 1.6rem;
                justify-content: flex-start;
                height: 100%;
                align-items: center;
                display: flex;
                &:hover{
                    box-shadow: $box-shadow;
                }
            }
        }

        .room-rate-info {
            color: #808080;
            margin-top: 1.6rem;
            font-size: 0.56rem;
            font-style: normal;
            font-weight: 100;
            line-height: 0.56rem;
        }

        .room-rate-price-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: .5rem;

            .room-rate-date, .room-rate-price {
                width: 50%;
            }

            .room-rate-price {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                text-align: right;
            }
        }
    }
}

#bookingModal.popup {
    z-index: 1000;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    padding: .5rem;
    
    .popup-content {
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        min-width: 50vw;
        transform: translate(-50%, -50%);
        background-color: $color-gray;
        padding: 5rem 3rem;
        max-height: calc(100dvh - 1rem);
        overflow-y: auto;
        overflow-x: hidden;
        @media (max-width: 1480px){
            min-width: 75vw;
        }
        .gfield{
            margin: 0 !important;
        }
        .gfield--type-textarea{
            grid-column: span 2;
        }
        .button{
            border-radius: $rounded;
            padding: 1rem 1.6rem;
            justify-content: flex-start;
            width: 100%;
            height: 100%;
            align-items: center;
            display: flex;
            &:hover{
                box-shadow: $box-shadow;
            }
        }
        @media (max-width: 767px){
            padding: 5rem 2rem 2rem;
            min-width: 90vw;
            .button{
                border-radius: $rounded-mobile;
            }
            .gfield--width-half{
                grid-column: span 2;
            }
            textarea {
                height: 160px;
            }
        }
    }
    
    .close-popup {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 1.6rem;
        width: 3rem;
        height: 3rem;
        right: 3rem;
        cursor: pointer;
        background-color: white;
        @media (max-width: 767px){
            right: 2rem;
        }
    }

    @media (max-width:991px) {
        .popup-content{
            width: 80vw;
        }
    }
}


@media (max-width:1440px) {
    .room-bloc {
        .room-infos {
            .room-capacity, .room-rate {
                grid-column: span 6;
            }
        }
    }
}
@media (max-width:991px) {
    .room-bloc {
        grid-template-columns: repeat(1, 1fr);
        padding: 0;
        .room-slider {
            order: 1 !important;
        }
        .room-infos {
            order: 2 !important;
            .room-capacity, .room-rate {
                grid-column: span 3;
            }
        }
    }
}

@media (max-width:767px) {
    .room-bloc {
        .room-infos {
            .room-capacity, .room-rate {
                grid-column: span 6;
            }
        }
    }
}