@import "../../assets/styles/scss/variables";

.member-section {
    padding: 6rem 0;

    @media (max-width:767px) {
        padding: 3rem 0;
    }

    .member-bloc-title {
        color: white;
    }
    
    .member-bloc {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 0.6rem;
        padding: 2.5rem 0 0;
        justify-content: space-between;
    
        .member-list {
            aspect-ratio: 1;
            padding: 1.2rem;
            width: 100%;
            max-width: 100%;
            background-color: white;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
    
            .member-function, .member-name, .catering-location {
                margin: 0;
                width: 100%;
                font-size: clamp(18px, 1.2vw, 25px);
            }
            .member-contact {
                display: flex; 
                gap: 0.6rem;
            }
            .catering-info {
                display: flex;
                flex-direction: column;
            }
        }
    
        .member-form {
            aspect-ratio: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            justify-content: center;
            padding: 1.2rem;
            width: 100%;
            max-width: 100%;
            background-color: white;
        
            svg {
                width: 100%;
                height: auto;
            }
        }
    }
}

@media (max-width:1440px) {
    .member-section {
        .member-bloc {
            grid-template-columns: repeat(4, 1fr);
            .member-list {

                .member-name {
                    font-size: 1.3rem;
                }

                .member-function, .catering-location {
                    font-size: 0.8rem;
                }
            }
            .member-form {
                padding: 1.2rem;

            }
        }
    }
}

@media (max-width:991px) {
    .member-section {
        .member-bloc {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

@media (max-width:576px) {
    .member-section {
        .member-bloc {
            grid-template-columns: repeat(3, 1fr);
            .member-list {

                .member-name {
                    font-size: 1rem;
                }

                .member-function, .catering-location {
                    font-size: 0.7rem;
                }
            }
            .member-form {
                padding: 1.2rem;
                display: flex;
                justify-content: center;
                svg{
                    width: 70%;
                }
            }
        }
    }
}

@media (max-width:484px) {
    .member-section {
        .member-bloc {
            grid-template-columns: repeat(2, 1fr);
            .member-list {

                .member-name {
                    font-size: 1.3rem;
                }

                .member-function, .catering-location {
                    font-size: 0.8rem;
                }
            }
        }
    }
}

@media (max-width:374px) {
    .member-section {
        .member-bloc {
            grid-template-columns: repeat(2, 1fr);
            .member-list {

                .member-name {
                    font-size: 1rem;
                }

                .member-function, .catering-location {
                    font-size: 0.6rem;
                }
            }
        }
    }
}