@import "../../assets/styles/scss/variables";

.cta-flexible {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 0.5rem;
    padding: 6rem 0;

    .cta-flex-title {
        grid-column: span 7;
        background-color: white;
        padding: 1.6rem 1.2rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .cta-flex-img {
        grid-column: span 5;
        background-color: white;
        padding: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;

        .cta-flex-image {
            display: flex;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
    }

    .cta-flex-icon {
        grid-column: span 2;
        aspect-ratio: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;

        svg {
            width: 100%;
            height: auto;
        }
    }


    .cta-flex-news-container  {
        display: grid;
        grid-column: span 5;
        gap: 0.5rem;
        grid-template-columns: repeat(5, 1fr);
        @media (max-width: 767px) {
            grid-column: span 5;
        }
    }
    .cta-flex-member-container  {
        display: grid;
        grid-column: span 12;
        gap: 0.5rem;
        grid-template-columns: repeat(12, 1fr);
        .cta-flex-icon{
            grid-column: span 1;
        }
        .cta-flex-content {
            grid-column: span 6;
        }
        .member-button {
            grid-column: span 5;
        }
        @media (max-width: 767px) {
            grid-column: span 5;
            grid-template-columns: repeat(5, 1fr);
            .cta-flex-icon{
                grid-column: span 1;
                grid-row-start: 3;
            }
            .cta-flex-content {
                grid-column: span 5;
                grid-row-start: 1;
            }
            .member-button {
                grid-column: span 5;
                grid-row-start: 2;
            }
        }
    }
    .cta-flex-icon.news-icon {
        grid-column: span 1;
    }

    .cta-flex-news, .member-button {
        grid-column: span 5;

        form {
            .gform_fields {
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                .gfield {
                    width: 100%;
                }
            }
            .gform_validation_errors {
                display: none;
            }
            .gform_footer{
                margin-top: 0.5rem !important;
            }
        }
        .button {
            border-radius: $rounded;
            padding: 1rem 1.6rem;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            width: 100%;
            height: 100%;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            @media (max-width: 767px) {
                border-radius: $rounded-mobile;
            }
        }
    }

    .cta-flex-content {
        grid-column: span 5;
        padding: 1.6rem 1.2rem;
        background-color: white;
    }

    .cta-flex-content::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 100%;
        width: 100%;
        background-color: #ecebeb;
        border-radius: 0 1rem 0 0;
        z-index: -1;
    }

    .cta-flex-highlight {
        grid-column: span 5;
        height: auto;
        background-color: white;
        padding: 2.7rem 2rem;
    }
}

#memberModal.popup {
    z-index: 1000;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    padding: .5rem;

	input, textarea, select {
		padding: 2.6rem 1.6rem 0.8rem;
    }
    
    .popup-content {
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        min-width: 50vw;
        transform: translate(-50%, -50%);
        background-color: $color-gray;
        padding: 4rem 3rem;
        max-height: calc(100dvh - 1rem);
        overflow-y: auto;
        overflow-x: hidden;
        .gfield{
            margin: 0 !important;
            .gfield_checkbox {

				.gchoice {

					label {

						&::before {
							position: absolute;
							top: .3rem;
							left: 0;
							transform: none;
							content: "";
							width: $xsmall;
							height: $xsmall;
							border-radius: 3px;
							border: 2px solid white;
							background-color: white;
							transition: all .2s linear;
						}
					}
				}
			}
        }
        .gfield--type-textarea{
            grid-column: span 2;
        }
        .button{
            border-radius: $rounded;
            padding: 1rem 1.6rem;
            justify-content: flex-start;
            width: 100%;
            height: 100%;
            align-items: center;
            display: flex;
            &:hover{
                box-shadow: $box-shadow;
            }
        }
        @media (max-width: 1480px){
            min-width: 75vw;
        }
        @media (max-width: 767px){
            padding: 5rem 2rem 2rem;
            min-width: 90vw;
            .button{
                border-radius: $rounded-mobile;
            }
            .gfield--width-half{
                grid-column: span 2;
            }
            textarea {
                height: 160px;
            }
        }
    }
    
    .close-popup {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 1.6rem;
        width: 3rem;
        height: 3rem;
        right: 3rem;
        cursor: pointer;
        background-color: white;
        @media (max-width: 767px){
            right: 2rem;
        }
    }

    @media (max-width:991px) {
        .popup-content{
            width: 80vw;
        }
    }
}

@media (max-width: 767px) {
    .cta-flexible {
        grid-template-columns: repeat(5, 1fr);
        padding: 3rem 0;
        
        .cta-flex-title, .cta-flex-highlight {
            grid-column: span 5;
        }

        .cta-flex-img {
            grid-column: span 5;
        }

        .cta-flex-icon {
            grid-column: span 1;
        }

        .cta-flex-content {
            grid-column: span 4;
        }
    }
}