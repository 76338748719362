@import "../../assets/styles/scss/variables";

#cta-footer {
    margin: 6rem 0;

    h2 {
        margin-bottom: $large;
    }

    .grid {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        width: calc(100% + .5rem);

        @media (max-width:576px) {
            width: 100%;
        }
    }

    .cta-bloc {
        background-color: white;
        padding: 1.6rem 2rem;
        padding-right: 4rem;
        padding-bottom: 4.8rem;
        width: calc(100% / 3 - .5rem);

        &:hover:not(.full) {
            filter: drop-shadow($drop-shadow);
        }

        &.full {
            width: 100%;
            background-color: transparent;
            border-radius: 0;
            grid-column: span 3;
            padding: 0;
            display: flex;
            gap: .5rem;
            margin-right: .5rem;

            h3 {
                background-color: white;
                padding: 1.6rem 2rem;
                padding-right: 4rem;
                padding-bottom: 14rem;
                width: calc(100% / 3 * 2);
                transition: all .2s linear;

                @media (max-width:767px) {
                    width: 100%;
                    min-height: 11rem;
                }
            }

            .img-container {
                width: calc(100% / 3);
                padding-top: calc(100% / 3);
                position: relative;
                overflow: hidden;

                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                @media (max-width:767px) {
                    width: 100%;
                    padding: 0;

                    img {
                        position: static;
                        height: auto;
                    }
                }
            }

            &:hover {
                filter: drop-shadow($drop-shadow);
            }

            .icon-bloc {
                width: calc(100% / 3);
                aspect-ratio: 1;

                @media (max-width:767px) {
                    width: 100%;
                }
            }

            @media (max-width:991px) {
                padding: 0 !important;
                
                h3 {
                    padding: 1.2rem !important;
                    padding-bottom: 1.6rem;
                }
            }

            @media (max-width:767px) {
                flex-direction: column;
            }

            @media (max-width:576px) {
                padding-bottom: 0 !important;
            }
        }

        @media (max-width:1520px) {
            padding: 1.5rem 1.9rem;
        }

        @media (max-width:1440px) {
            padding: 1.4rem 1.8rem;
        }

        @media (max-width:1080px) {
            padding: 1.2rem 1.2rem 1.6rem;
        }

        @media (max-width:767px) {
            width: calc(50% - .5rem);
        }

        @media (max-width:576px) {
            width: 100% !important;
            margin-right: 0 !important;
            padding: 1rem !important;
            min-height: 5.5rem;
        }
    }
}