//
// Header Styles
// --------------------------------------------------

#header {
	width: 100%;
	padding-bottom: 3.6rem;
	padding-top: 2rem;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 20;
	transition: all 0.4s linear;
	color: black;

	@media (max-width:767px) {
		padding: $xtrasmall 0 !important;

		&.sticky {
			padding: $xtrasmall 0 !important;
		}

		#menu {
			#menu-main-container {
				padding-top: $xtrasmall !important;
			}
		}
	}

	&.sticky {
		padding: $small 0;
		background-color: $color-gray;
		transition: all 0.4s linear;

		#logo {
			
			a {
				height: 100%;
			}
		}

		@media (max-width:991px) {

			#logo {
				max-width: 10rem;
				height: 3.2rem;
			}

			#burger-menu-trigger {
				width: 3.2rem;
				height: 3.2rem;

				span {
					width: 1.6rem;
					height: .3rem;
				}
			}

			#burger-menu-close,
			.back-btn {
				width: 3.2rem !important;
				height: 3.2rem !important;

				svg {
					width: 1.1rem !important;
				}
			}
		}

		#burger-menu-close,
		.back-btn { 
			border-radius: $rounded-mobile; 
		}
		
		#menu {
			#menu-main-container {
				padding-top: $small;
			}
		}
	}

	#header-content {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		position: relative;
	}

	#burger-menu-trigger {
		width: 4.5rem;
		height: 4.5rem;
		background-color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: .4rem;
		border-radius: $rounded;

		span {
			width: 2.4rem;
			height: .4rem;
			border-radius: .1rem;
			transition: all .2s linear;
			background-color: $color-black;
		}

		@media (max-width:1441px) {
			width: 2.9rem;
			height: 2.9rem;
			gap: .3rem;

			span {
				width: 2rem;
				height: .3rem;
			}
		}

		@media (max-width:767px) {
			border-radius: .15rem !important;
			width: 3.2rem;
			height: 3.2rem;

			span {
				width: 1.6rem;
				height: .3rem;
			}
		}

		&:hover {
			gap: .6rem;
		}
	}

	#logo {
		width: 100%;
		max-width: 30rem;
		a {
			display: inline-block;
		}

		img {
			width: 100%;
			height: auto;
		}

		@media (max-width:1441px) {
			max-width: 20rem;
		}

		@media (max-width:767px) {
			max-width: 10rem;
			height: 3.2rem;
		}

		.icon-bloc {
			transform-origin: center center;
			transition: transform .2s linear;
		}

		&:hover {

			.icon-bloc {
				transform: scale(.8);
			}
		}
	}

	#menu {
		position: fixed;
		top: 0;
		right: -100vw;
		width: 100vw;
		height: 100vh;
		max-height: 100dvh;


		&.is-open {
			right: 0;
			&::before {
				opacity: .4;
				transition: all 0.2s ease;
				transition-delay: 0.2s;
			}

			.right-part {
				opacity: 1 !important;
			}
			.bottom-part {
				opacity: 1 !important;
				transform: none !important;
			}
		}
		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: black;
			opacity: 0;
			transition: all 0.2s ease;
			z-index: -1;
		}

		.container {
			height: 100%;
		}
		#menu-main-container {
			display: flex;
			flex-direction: row-reverse;
			align-items: flex-end;
			column-gap: .5rem;
			height: 100%;
			width: calc(100% - .5rem);
			margin-left: auto;
			background-color: transparent;
			color: $color-text;
			padding-top: 2rem;
			padding-bottom: .5rem;

			@media (max-width:991px) {
				flex-direction: column;
				row-gap: .5rem;
				width: 100%;
			}

			.right-part {
				height: 100%;
				min-width: 50%;
				transition-duration: .2s;
				transition-delay: .2s;
				transition-property: opacity;
				opacity: 0;

				@media (max-width:991px) {
					min-width: 100%;
					display: flex;
					flex-direction: column;
				}
			}

			#nav {
				width: 100%;
				height: calc(100% - 5rem);
				position: relative;
				align-self: flex-start;
				overflow-x: hidden;
				overflow-y: auto;
				background-color: white;
				padding: 1rem;

				li {
					margin-bottom: $regular;

					a {
						display: block;
						width: 100%;
						font-size: $massive;
						line-height: normal;
						font-weight: 500;

						&:hover {
							color: $color-link-hover;
						}
					}
				}

				@media (max-width:1441px) {
					height: calc(100% - 3.4rem);
				}

				@media (max-width:991px) {
					height: 100%;
				}
			}
			.second-level-container {
				background-color: white;
				position: absolute;
				top: 0;
				left: 200%;
				width: 100%;
				height: 100%;
				z-index: 1;
				padding: 1rem;

				&.is-open {
					left: 0;
				}
			}

			.top-part {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;

				@media (max-width:767px) {
					justify-content: flex-end;
					gap: 1rem;

					#burger-menu-close {
						margin-left: 0 !important;
					}
				}

				.back-btn {
					width: 4.5rem;
					height: 4.5rem;
					background-color: white;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: $rounded;
					padding: 0;
					margin-bottom: .5rem;

					@media (max-width:1441px) {
						width: 2.9rem;
						height: 2.9rem;

						svg {
							width: 2rem !important;
						}
					}

					@media (max-width:767px) {
						border-radius: $rounded-mobile;
					}

					svg {
						transform: rotate(-90deg);
						width: 2.4rem;
						height: auto;
						transition: transform .2s linear;
					}

					&:hover {

						svg {
							transform: scale(1.1) rotate(-90deg);
						}
					}
				}

				#burger-menu-close {
					width: 4.5rem;
					height: 4.5rem;
					background-color: white;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: $rounded;
					padding: 0;
					margin-bottom: .5rem;
					margin-left: auto;

					@media (max-width:1441px) {
						width: 2.9rem;
						height: 2.9rem;

						svg {
							width: 2rem !important;
						}
					}

					@media (max-width:767px) {
						border-radius: $rounded-mobile;
					}

					svg {
						width: 2.4rem;
						height: auto;
						transition: transform .2s linear;
						transform-origin: center;
					}

					&:hover {

						svg {
							transform: rotate(180deg);
						}
					}
				}
			}

			.bottom-part {
				min-width: 50%;
				width: 100%;
				padding: 1rem;
				background-color: white;
				font-size: $small;
				line-height: normal;
				opacity: 0;
				transform: translateX(-20%);
				transition-duration: .2s;
				transition-delay: .4s;
				transition-property: transform opacity;

				.socials {
					display: flex;
					align-items: flex-start;
					flex-wrap: wrap;
					gap: $large;
					margin-top: 2rem;
				}

				@media (max-width:991px) {
					min-width: 100%;
				}

				@media (max-width:767px) {
					display: none;
					visibility: hidden;
				}
			}
			ul {
				margin: 0;
				padding: 0;
				list-style: none;
				li {
					&:last-child {
						margin-right: 0;
					}
					&.current-menu-item,
					&.current-menu-ancestor,
					&.current-menu-parent,
					&.current-product-ancestor,
					&.current-product_cat-ancestor,
					&.current-page-ancestor {
						> a {
							color: #ffffff;
						}
						.sub-menu {
							li {
								a {
									color: $color-primary;
								}
							}
						}
					}
					.sub-menu {
						opacity: 0;
						height: 0;
						padding: 0;
						position: absolute;
						text-align: left;
						margin-left: -30px;
					    transform: scale(0.95) translateY(20px);
						transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
						z-index: 10;
						overflow: hidden;
						li {
							white-space: nowrap;
							margin: 0;
							float: none;
							a {
								display: block;
								color: $color-primary;
								background-color: $color-gray-darker;
								padding: 20px 35px;
								&:hover {
									color: $color-link-hover;
									background-color: lighten($color-gray-darker, 3%);
								}
							}
							&:last-child {
								margin-bottom: 0;
							}
							&.current-menu-item,
							&.current-menu-ancestor,
							&.current-menu-parent,
							&.current-product-ancestor,
							&.current-product_cat-ancestor,
							&.current-page-ancestor {
								a {
									color: #ffffff;
								}
							}
						}
					}
					&.menu-item-has-children {
						&:after {
							font-family: "Font Awesome 5 Pro";
							content: '\f105';
							display: block;
							position: absolute;
							top: 50%;
							right: -12px;
							font-weight: 100;
							transform: translateY(-50%);
							transform-origin: center;
							color: white;
							transition: transform 0.25s;
						}
						&:hover {
							&:after {
								transform: translateY(-50%) rotate(90deg);
							}
							.sub-menu {
								height: auto;
								opacity: 1;
							    transform: scale(1) translateY(0);
								padding-top: 27px;
							}
						}
					}
				}
			}

			@media (max-width:767px) {
				padding-top: $xtrasmall;
			}
		}
	}
}
