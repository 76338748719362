@import "../../assets/styles/scss/variables";

.news-bloc {
  display: flex;
  gap: .5rem;
  transition: all .2s linear;
  flex-direction: column;
  justify-content: flex-end;

  .infos {
    background-color: white;
    padding: .9rem 1.3rem;
    line-height: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    
    .dates {
      color: $color-gray-darker;
    }
  }

  .img-container {
    width: 100%;
    max-height: 19.88rem;
    height: 19.88rem;
    min-height: 19.88rem;
    position: relative;
    overflow: hidden;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (max-width:576px) {
    min-height: 5.5rem;
  }
}

a.news-bloc {
  &:hover {
    filter: drop-shadow($drop-shadow);

    .infos {

      .dates {
        color: $color-text;
      }
    }
  }
}