@import "../../assets/styles/scss/variables";

.exhibition-bloc {
  display: grid;
  grid-template-rows: 1fr auto;
  gap: .5rem;
  transition: all .2s linear;

  .infos {
    background-color: white;
    padding: .9rem 1.3rem;
    line-height: 1;

    .dates {
      color: $color-gray-darker;
    }

    @media (max-width:576px) {
      padding: .5rem .8rem;
      min-height: 5.5rem;
    }
  }

  .img-container {
    width: 100%;
    position: relative;
    padding-top: 100%;
    overflow: hidden;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

a.exhibition-bloc {
  &:hover {
    filter: drop-shadow($drop-shadow);

    .infos {

      .dates {
        color: $color-text;
      }
    }
  }
}