@import "../../assets/styles/scss/variables";

#page-header.home-header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: .5rem;

  &:hover {
    filter: drop-shadow($drop-shadow);
  }

  @media (max-width:767px) {
    display: flex;
    flex-direction: column;
  }

  .img-container {
    position: relative;
    padding-top: 100%;
    overflow: hidden;

    img, video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .title, .dates {
    background-color: white;
  }

  .title {
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1rem;

    @media (max-width:576px) {
      padding: .6rem;
    }
  }

  .dates {
    width: fit-content;
    padding: .8rem 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width:576px) {
      padding: .8rem;
      width: 100%;
    }
  }

  .icon-bloc {
    aspect-ratio: 1;
    width: auto;
    height: 100%;
    max-height: 6.5rem;

    svg {
      width: auto;
      height: 100%;
    }
  }

  .infos {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: .5rem;

    @media (max-width:576px) {
      display: grid;
      gap: .5rem;
      grid-template-columns: 3fr 1fr;
    }
  }

  .right {
    display: grid;
    grid-template-rows: 5fr 1fr;
    gap: .5rem;

    @media (max-width:767px) {
      display: flex;
      flex-direction: column;

      .title {
        min-height: 20vh;
      }
    }
  }
}